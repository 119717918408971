import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { Card, RadioGroup } from '@calm-web/design-system';
import { BindWithErrorProps } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import useHealthConfig from '@/hooks/api/useHealthConfig';
import { usePermissions } from '@/hooks/auth/usePermissions';

import messages from './messages';

interface Props {
	isEdit: boolean;
	chResourcesToggleProps: ReturnType<BindWithErrorProps<string>>;
}

export default function ChResourcesToggle({ chResourcesToggleProps, isEdit }: Props): ReactElement | null {
	const { partnerId } = useParams<{ partnerId: string }>();
	const [hasValidPermissions, actions] = usePermissions();
	const shouldShowChResourcesToggle = hasValidPermissions('are_health_resources_published', [actions.UPDATE]);
	const { formatMessage } = useIntl();
	const { data, loading } = useHealthConfig({ partnerId });

	if (!isEdit || !shouldShowChResourcesToggle || loading) {
		return null;
	}

	const options = [
		{
			value: true.toString(),
			id: 'chResourcesPublished',
			label: 'Resources On in App',
		},
		{
			value: false.toString(),
			id: 'notChResourcesPublished',
			label: 'Resources Off in App',
		},
	];

	return (
		<Card
			warningText={
				data?.are_health_resources_published ? null : formatMessage(messages.chResourcesOffWarning)
			}
		>
			<CellTitle showAdminFlag>Resources</CellTitle>
			<RadioGroup
				{...chResourcesToggleProps}
				options={options}
				asCards
				aria-label="Whether the Calm Health Resources are published in the app"
				display="flex"
			/>
		</Card>
	);
}
