import { defineMessages } from 'react-intl';

export default defineMessages({
	chResourcesOffWarning: {
		id: 'chResourcesToggle.resourcesOffWarning',
		defaultMessage: 'Resources are currently off in the app.',
		description:
			'Text shown in the warning banner that shows in the Calm Health Resources toggle widget when Calm Health Resources are configured to be turned off within the app.',
	},
});
