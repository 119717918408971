import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useForm, {
	booleanFromCheckbox,
	FormProps,
	stringFromModelValue,
	validation,
} from '@calm-web/use-form';

import { EligibilityFileFormatType } from '@/components/pages/Account/EligibilityFileFormat';
import { setBannerMessage } from '@/store/actions';
import { EligibilityValidator, ErrorButtonType, ErrorConfigsType, HealthConfig } from '@/types/health';
import { IntegrationType } from '@/types/store/reducers';

import { usePermissions } from '../auth';
import { useShouldShowEFFileFormatTemplate } from '../useShouldShowEFFileFormatTemplate';
import {
	EditHealthEligibilityValidatorFormProps,
	useHealthEligibilityValidatorForms,
} from './useHealthEligibilityValidatorForm';
import {
	EditErrorConfigFormProps,
	useHealthErrorCustomizationConfigForm,
} from './useHealthErrorCustomizationConfigForm';

interface UseHealthConfigSubmitDataResponse {
	getHealthConfigSubmitData: () => Partial<HealthConfig>;
	showValidationErrors: () => boolean;
	showAdminValidationErrors: () => boolean;
}

const getErrorMessage = (
	props: EditHealthConfigFormProps | EditHealthEligibilityValidatorFormProps | EditErrorConfigFormProps,
): string | undefined => {
	const genericProps = props as FormProps<string>;
	return stringFromModelValue(
		Object.keys(genericProps.validation.fields)
			.map(fieldName => stringFromModelValue(genericProps.validation.fields[fieldName]?.errors))
			.filter(Boolean) as string[],
	);
};

export const useHealthConfigSubmitData = (
	baseFormProps: EditHealthConfigFormProps,
	eligibilityValidatorFormProps: EditHealthEligibilityValidatorFormProps[],
	errorCustomizationFormProps: EditErrorConfigFormProps,
): UseHealthConfigSubmitDataResponse => {
	const dispatch = useDispatch();
	const [hasValidPermissions, actions] = usePermissions();
	const shouldShowEligibilityFileFormat = useShouldShowEFFileFormatTemplate();

	const getHealthConfigSubmitData = useCallback((): Partial<HealthConfig> => {
		const shouldReturnEmail = ['email', 'both'].includes(baseFormProps.model.clientSupportChannel as string);
		const shouldReturnPhone = ['phone', 'both'].includes(baseFormProps.model.clientSupportChannel as string);

		const baseHealthConfigData: Partial<HealthConfig> = {
			landing_header: stringFromModelValue(baseFormProps.model.landingHeader),
			landing_body: stringFromModelValue(baseFormProps.model.landingBody),
			baa_data_retention:
				stringFromModelValue(baseFormProps.model.baaDataRetention) === 'true' ? true : false,
			enable_data_feed_override:
				stringFromModelValue(baseFormProps.model.enableDataFeedOverride) === 'true' ? true : false,
			hide_all_referrals: !booleanFromCheckbox(baseFormProps.model.show_referrals, 'show_referrals'),
			client_support_email: shouldReturnEmail
				? stringFromModelValue(baseFormProps.model.clientSupportEmail)
				: null,
			client_support_phone: shouldReturnPhone
				? stringFromModelValue(baseFormProps.model.clientSupportPhone)
				: null,
			integration_type: stringFromModelValue(baseFormProps.model.integration_type) as IntegrationType,
			client_id: stringFromModelValue(baseFormProps.model.client_id),
			eligibility_file_format:
				(stringFromModelValue(baseFormProps.model.eligibility_file_format) as EligibilityFileFormatType) ||
				null,
			implied_sso_eligibility_enabled:
				stringFromModelValue(baseFormProps.model.impliedSSOEligibilityEnabled) === 'true' ? true : false,
			are_health_resources_published:
				stringFromModelValue(baseFormProps.model.areHealthResourcesPublished) === 'true' ? true : false,
		};

		// Clear out the support details as they need to be removed and no longer reflected prior to submission
		if (!shouldReturnEmail) {
			baseFormProps.setProperty('clientSupportEmail', '');
		}
		if (!shouldReturnPhone) {
			baseFormProps.setProperty('clientSupportPhone', '');
		}

		Object.keys(baseHealthConfigData).forEach((partnerField: string) => {
			const updatedPartnerField = partnerField === 'client_id' ? 'health_client_id' : partnerField;
			if (!hasValidPermissions(updatedPartnerField, [actions.UPDATE])) {
				delete baseHealthConfigData[partnerField as keyof HealthConfig];
			}
		});

		// if user is allowed to delete validators, return empty array
		let eligibilityValidatorData: EligibilityValidator[] | undefined;
		if (
			eligibilityValidatorFormProps.length === 0 &&
			hasValidPermissions('eligibility_validator_validation_name', [actions.DELETE])
		) {
			eligibilityValidatorData = [];
		} else {
			// otherwise, filter out any values the user does not have permission to edit
			const permissionFilteredValidators = eligibilityValidatorFormProps
				.map(props => {
					const baseEligibilityValidatorData = {
						validation_name: props.model.validation_name as string,
						validation_type: props.model.validation_type as 'text' | 'date' | 'email' | 'phone' | 'numeric',
						validation_display_name: props.model.validation_display_name as string,
						// Hard code this as true until it's user-configurable
						is_required_field_at_redemption: true,
					};
					Object.keys(baseEligibilityValidatorData).forEach((partnerField: string) => {
						if (!hasValidPermissions(`eligibility_validator_${partnerField}`, [actions.UPDATE])) {
							delete baseEligibilityValidatorData[partnerField as keyof typeof baseEligibilityValidatorData];
						}
					});
					return Object.keys(baseEligibilityValidatorData).length > 0
						? baseEligibilityValidatorData
						: undefined;
				})
				.filter(Boolean) as EligibilityValidator[];
			// if there are no validators left after permission checks, return undefined so we don't send any updates to the API
			eligibilityValidatorData =
				permissionFilteredValidators.length > 0 ? permissionFilteredValidators : undefined;
		}

		const buttonType = (value?: string): ErrorButtonType | null => {
			return value ? (value as ErrorButtonType) : null;
		};

		const baseErrorConfigData: Partial<HealthConfig> = {
			error_configs: [
				{
					error_type: ErrorConfigsType.Eligibility,
					error_body: stringFromModelValue(errorCustomizationFormProps.model.error_body) ?? '',
					error_button_text: stringFromModelValue(errorCustomizationFormProps.model.error_button_text) ?? '',
					error_button_type: buttonType(
						stringFromModelValue(errorCustomizationFormProps.model.error_button_type),
					),
					error_button_data: stringFromModelValue(errorCustomizationFormProps.model.error_button_data) ?? '',
				},
			],
		};

		Object.keys(baseErrorConfigData).forEach((partnerField: string) => {
			if (!hasValidPermissions(partnerField, [actions.UPDATE])) {
				delete baseErrorConfigData[partnerField as keyof typeof baseErrorConfigData];
			}
		});

		return {
			...baseHealthConfigData,
			eligibility_validators: eligibilityValidatorData,
			...baseErrorConfigData,
		};
	}, [
		actions.UPDATE,
		actions.DELETE,
		baseFormProps,
		eligibilityValidatorFormProps,
		errorCustomizationFormProps,
		hasValidPermissions,
	]);

	const showValidationErrors = useCallback((): boolean => {
		const allFormProps = [baseFormProps, ...eligibilityValidatorFormProps, errorCustomizationFormProps];
		if (allFormProps.every(props => props.validation.isValid)) {
			return false;
		}
		const errorMessage =
			stringFromModelValue(allFormProps.map(getErrorMessage).filter(Boolean) as string[]) ??
			'Please check you have filled out all required fields';
		if (errorMessage === 'Please select an eligibility file template' && !shouldShowEligibilityFileFormat) {
			return false;
		}
		dispatch(
			setBannerMessage({
				message: `Error: ${errorMessage}`,
				flash: true,
				isError: true,
			}),
		);
		return true;
	}, [
		dispatch,
		eligibilityValidatorFormProps,
		baseFormProps,
		errorCustomizationFormProps,
		shouldShowEligibilityFileFormat,
	]);

	const showAdminValidationErrors = useCallback((): boolean => {
		if (!shouldShowEligibilityFileFormat) {
			return false;
		}
		if (baseFormProps.validation.fields.eligibility_file_format?.isValid) {
			return false;
		}
		const errorMessage =
			baseFormProps.validation.fields.eligibility_file_format?.errors ??
			'Please check you have filled out all required fields';

		dispatch(
			setBannerMessage({
				message: `Error: ${errorMessage}`,
				flash: true,
				isError: true,
			}),
		);
		return true;
	}, [dispatch, baseFormProps, shouldShowEligibilityFileFormat]);

	return { getHealthConfigSubmitData, showValidationErrors, showAdminValidationErrors };
};

const useBaseHealthConfigForm = (
	healthConfig?: Pick<
		HealthConfig,
		| 'landing_header'
		| 'landing_body'
		| 'baa_data_retention'
		| 'enable_data_feed_override'
		| 'hide_all_referrals'
		| 'client_support_email'
		| 'client_support_phone'
		| 'integration_type'
		| 'client_id'
		| 'eligibility_file_format'
		| 'error_configs'
		| 'implied_sso_eligibility_enabled'
		| 'are_health_resources_published'
	>,
): {
	formProps: EditHealthConfigFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	const formProps: EditHealthConfigFormProps = useForm('healthConfigForm', {
		initialModel: {
			landingHeader: healthConfig?.landing_header ?? '',
			landingBody: healthConfig?.landing_body ?? '',
			baaDataRetention: healthConfig?.baa_data_retention ? 'true' : 'false',
			enableDataFeedOverride: healthConfig?.enable_data_feed_override ? 'true' : 'false',
			show_referrals: healthConfig?.hide_all_referrals ? [] : ['show_referrals'],
			clientSupportEmail: healthConfig?.client_support_email ?? '',
			clientSupportPhone: healthConfig?.client_support_phone ?? '',
			clientSupportChannel:
				healthConfig?.client_support_email && healthConfig?.client_support_phone
					? 'both'
					: healthConfig?.client_support_email
					? 'email'
					: 'phone',
			integration_type: healthConfig?.integration_type ?? IntegrationType.GROUP_CODE,
			client_id: healthConfig?.client_id ?? '',
			eligibility_file_format: healthConfig?.eligibility_file_format ?? '',
			impliedSSOEligibilityEnabled: healthConfig?.implied_sso_eligibility_enabled ? 'true' : 'false',
			areHealthResourcesPublished: healthConfig?.are_health_resources_published ? 'true' : 'false',
		},
		validation: {
			landingHeader: validation.validateOrFail([
				{
					rules: [validation.maxLength(50)],
					errorResult: 'Please enter a header with a maximum of 50 characters',
				},
			]),
			landingBody: validation.validateOrFail([
				{
					rules: [validation.maxLength(250)],
					errorResult: 'Please enter a body with a maximum of 250 characters',
				},
			]),
			clientSupportEmail: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							const isEmailRequired = ['both', 'email'].includes(props.model.clientSupportChannel as string);
							if (isEmailRequired) {
								return validation.email(value);
							}
							return true;
						},
					],
					errorResult: 'Please enter a valid email address',
				},
			]),
			clientSupportPhone: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							const isPhoneRequired = ['both', 'phone'].includes(props.model.clientSupportChannel as string);
							if (isPhoneRequired) {
								return validation.phone(value);
							}
							return true;
						},
					],
					errorResult: 'Please enter a valid phone number',
				},
			]),
			eligibility_file_format: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							const isTemplateRequired = props.model.integration_type === IntegrationType.ELIGIBILITY_FILE;
							if (isTemplateRequired) {
								return !!value;
							}
							return true;
						},
					],
					errorResult: 'Please select an eligibility file template',
				},
			]),
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

export const useHealthConfigForm = (
	healthConfig?: Pick<
		HealthConfig,
		| 'landing_header'
		| 'landing_body'
		| 'baa_data_retention'
		| 'enable_data_feed_override'
		| 'hide_all_referrals'
		| 'client_support_email'
		| 'client_support_phone'
		| 'integration_type'
		| 'client_id'
		| 'eligibility_validators'
		| 'eligibility_file_format'
		| 'error_configs'
		| 'implied_sso_eligibility_enabled'
		| 'are_health_resources_published'
	>,
): {
	baseFormProps: EditHealthConfigFormProps;
	eligibilityValidatorFormProps: EditHealthEligibilityValidatorFormProps[];
	errorCustomizationFormProps: EditErrorConfigFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
	resetAllDirtyStates: () => void;
	addEligibilityValidator: () => void;
	removeEligibilityValidator: (index: number) => void;
} => {
	const baseForm = useBaseHealthConfigForm(healthConfig);
	const eligibilityValidatorForms = useHealthEligibilityValidatorForms(healthConfig);
	const errorCustomizationForm = useHealthErrorCustomizationConfigForm(healthConfig);

	const hasChangedAny =
		baseForm.hasChangedAny || eligibilityValidatorForms.hasChangedAny || errorCustomizationForm.hasChangedAny;
	const hasTouchedAny =
		baseForm.hasTouchedAny || eligibilityValidatorForms.hasTouchedAny || errorCustomizationForm.hasTouchedAny;

	const resetAllDirtyStates = useCallback((): void => {
		baseForm.formProps.resetAllDirtyStates();
		eligibilityValidatorForms.resetAllDirtyStates();
		errorCustomizationForm.formProps.resetAllDirtyStates();
	}, [baseForm.formProps, eligibilityValidatorForms, errorCustomizationForm]);

	return {
		baseFormProps: baseForm.formProps,
		eligibilityValidatorFormProps: eligibilityValidatorForms.eligibilityValidatorFormProps,
		errorCustomizationFormProps: errorCustomizationForm.formProps,
		hasChangedAny,
		hasTouchedAny,
		resetAllDirtyStates,
		addEligibilityValidator: eligibilityValidatorForms.addEligibilityValidator,
		removeEligibilityValidator: eligibilityValidatorForms.removeEligibilityValidator,
	};
};

export type FieldNames =
	| 'landingHeader'
	| 'landingBody'
	| 'baaDataRetention'
	| 'enableDataFeedOverride'
	| 'show_referrals'
	| 'clientSupportEmail'
	| 'clientSupportPhone'
	| 'clientSupportChannel'
	| 'integration_type'
	| 'client_id'
	| 'eligibility_file_format'
	| 'impliedSSOEligibilityEnabled'
	| 'areHealthResourcesPublished';

export type EditHealthConfigFormProps = FormProps<FieldNames>;
